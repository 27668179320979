<template>
  <div>
    <div
      v-if="Object.keys(adsTypes).length > 1"
      class="w-full lg:w-11/12 mx-auto flex items-center justify-end"
    >
      <RikazHorizontalDrag class="h-10">
        <div class="flex">
          <button
            v-for="key in adsTypes"
            :key="`ad-hooper-slider-${key}`"
            class="relative px-3 py-1 mx-1 text-text_color bg-bg_color rounded-full focus:outline-none"
            :class="{ tail: activeKeyType == key }"
            @click="emit('select-type',key)"
          >
            {{ key }}
          </button>
        </div>
      </RikazHorizontalDrag>
    </div>
    <SelaSwiper
      :key="`swiper-${activeKeyType}`"
      swiper-pagination
      thumb
      thumb-slide-class="ads-slide"
      :items="activeAdsGroupByType"
    >
      <template #slide="{ item }">
        <HomeAdMainMobileSlide :ad="item" :width="170" :height="100" />
      </template>
    </SelaSwiper>
  </div>
</template>

<script setup lang="ts">

import type { Ad } from '~~/composables/useMenuModel'

defineProps<{
  adsTypes: string[]
  activeKeyType: string
  activeAdsGroupByType: Ad[]
}>()
const emit = defineEmits(['select-type'])
</script>

<style>
.ads-slide {
  @apply w-max bg-bg_color rounded-lg shadow cursor-pointer mx-1 border-2 border-bg_color;
}
#secondary-swiper > .swiper > .swiper-pagination.main-pagination {
  @apply top-auto bottom-4;
}
.swiper-pagination.main-pagination > .swiper-pagination-bullet-active {
  @apply w-3 bg-nav_color rounded-full !important;
}
</style>
