<template>
  <div class="flex mx-2">
    <LayoutNavbarPlatformNotification />

    <LayoutNavbarPlatformCitiesFilter />
    <!-- Cart -->
    <RikazAction
      route="/cart"
      :has-badge="true"
      :transition-key="cart.badgeCount"
      position="top-0 ltr:left-1 rtl:right-1"
      :label="t('aria_page_label', { pae: t('cart') })"
    >
      <div class="navbar-action">
        <div class="navbar-link">
          <div class="navbar-title">{{ t('cart') }}</div>
          <v-icon :size="24">
            <IconsCart />
          </v-icon>
        </div>
      </div>
      <template #badge>
        <ClientOnly>
          <div
            v-if="cart.badgeCount"
            class="action-badge"
            :class="{ platform: layout == 'platform' }"
          >
            <div>{{ cart.badgeCount }}</div>
          </div>
        </ClientOnly>
      </template>
    </RikazAction>

    <RikazAction
      route="/my-account"
      label="profile page"
    >
      <div class="navbar-action">
        <div class="navbar-link">
          <div class="navbar-title">
            <span v-if="isAuthLoggedIn()">{{ t('profile') }}</span>
            <span v-else>{{
              t('login')
            }}</span>
          </div>
          <v-icon icon="mdi mdi-account-outline" :size="30" />
        </div>
      </div>
    </RikazAction>
  <!--   <LayoutNavbarLanguageLocales
      :enable-title="false"
    /> -->
  </div>
</template>

<script setup lang="ts">
const { isAuthLoggedIn } = useMenuModel()
const cart = useCartStore()
const { layout } = useDomainHost()
const { t } = useI18n()

</script>

<style>
.navbar-action {
  @apply transition-all duration-300 rounded hover:bg-nav_color hover:bg-opacity-10 mx-0.5;
}
.navbar-link {
  @apply flex items-center p-2;
}
.navbar-title {
  @apply mx-1 hidden font-normal h-5;
}
.size-icon {
  font-size: 90px !important;
  height: 90px !important;
  width: 90px !important;
}

.router-link-exact-active {
  @apply bg-nav_color rounded bg-opacity-10;
}

.action-badge {
  @apply h-4 px-1 flex items-end justify-center text-xs text-text_color rounded-full bg-bg_color leading-none;
}

.action-badge.platform {
  @apply text-text_alt_color bg-nav_color;
}
</style>
