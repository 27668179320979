<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 841.89 841.89"
  >
    <path
      class="cls-2"
      d="m309.91,381.29c-15.22-14.02-40.42-14.32-59.37-13.97-23.61.44-47.41,5.03-69.47,14.27-24.02,10.05-50.13,27.46-58.95,54.78-7.25,22.45,3.63,33.39,20.04,46.07,13.84,10.69,31.2,19.03,41.28,34.3,12.04,18.23,3.6,42.64-12.08,55.14-17.5,13.93-53.82,24.4-81.55,30.42,3.99,6.28,19.1,1.52,25.02.5,8.99-1.55,17.82-4.08,26.52-6.92,25.59-8.34,60.72-24.6,69.34-54.49,9.36-32.43-34.93-50.61-54.81-61.81-6.42-3.62-13.15-8.29-15.2-15.73-1.73-6.29.42-13.24,4.15-18.5,14.83-20.93,45.77-35.82,68.62-42.93,16.43-5.11,33.87-7.97,50.93-9.08,11.54-.75,26.83-1.22,36.35,6.66,5.3,4.39,7.96,11.4,8.21,18.44.28,8.03-4.35,14.37-7.83,21.03-.63,1.2-2.61,4.5-.92,5.45,1.27.71,3.89-1.79,4.67-2.58,2.28-2.31,4.32-4.9,6.31-7.49,5.88-7.63,10.53-15.63,10.73-25.82.19-9.66-3.58-18.84-9.88-25.64-.68-.73-1.38-1.43-2.11-2.1Z"
    />
    <path
      class="cls-2"
      d="m294.58,446.49c3.72,0-4.36,6.36-5,6.99-29.29,28.24-52.54,65.43-50.69,107.59.4,9.22,2.1,18.58,6.72,26.57,8.08,13.95,21.57,18.01,36.28,12.43,20.25-7.69,37.44-21.82,53.96-35.4,19.07-15.67,36.09-33.76,50.65-53.7,1.7-2.32,4.01-4.95,6.84-4.44,4.95.9,3.36,8.44.47,12.56-18.31,26.1-39.79,49.99-63.8,70.96-15.65,13.67-32.86,26.35-52.83,32.11-12.55,3.62-26.89,4.11-37.86-2.97-8.98-5.79-14.44-15.91-16.85-26.32-3.44-14.87-3.99-35.42-.74-50.5,2.1-9.76,4.55-19.38,8.45-28.63,1.8-4.27,3.84-8.44,6.1-12.48,5.68-10.12,12.75-19.43,20.86-27.73,6.39-6.54,13.42-12.48,20.95-17.68,4.5-3.11,9.19-6.06,14.14-8.4.64-.3,1.62-.97,2.36-.97Z"
    />
    <path
      class="cls-2"
      d="m412.52,522.43c-5.41,15.32-8.34,25.57-8.78,30.75-.46,5.18-.68,12.16-.68,20.95s2.25,16.79,6.76,23.99c7.65,12.16,20.5,13.06,38.52,2.7,13.52-7.2,30.64-20.38,51.36-39.53,20.72-19.14,36.72-36.6,47.98-52.37.9-1.35,2.25-2.03,4.05-2.03,4.05,0,5.17,2.94,3.38,8.79-6.76,12.16-19.26,27.7-37.51,46.63-18.25,18.92-34.8,33.9-49.67,44.94-14.87,11.03-29.74,16.56-44.6,16.56-34.24,0-51.36-16.66-51.36-50.01v-4.05c1.8-31.52,17.79-79.17,47.99-142.92,30.17-63.75,57.44-111.84,81.76-144.28l20.95-26.36c4.95-5.85,12.16-13.06,21.63-21.63,18.01-17.11,36.6-25.67,55.75-25.67s32.1,5.86,38.86,17.57c2.25,4.05,3.38,9.91,3.38,17.56s-3.38,18.48-10.13,32.44c-46.41,80.65-119.61,162.64-219.63,245.99Zm201.38-258.15c5.41-9.91,8.11-17.57,8.11-22.98,0-9.46-3.83-14.19-11.49-14.19-6.31,0-14.19,3.61-23.65,10.81-36.05,27.94-70.96,72.09-104.75,132.45-27.03,48.66-46.86,90.34-59.47,125.02,31.09-21.17,65.89-55.64,104.41-103.39,38.52-47.75,67.46-90.32,86.84-127.72Z"
    />
    <path
      class="cls-1"
      d="m343.17,372.7c-12.77,9.66-16.11-13.06-38.07-20.5-21.96-7.44-39.94,2.26-41-11.42-1.23-15.96,27.51-23.69,49.46-16.25,21.96,7.44,41.6,39.09,29.6,48.17Z"
    />
    <path
      class="cls-2"
      d="m750.24,550.1c2.02-14.19.08-29.31-6.67-38.32-5.58-7.44-14.7-11.42-23.8-13.33-23.33-4.89-47.49-3.95-70.97-.76-23.25,3.15-46.26,9.51-68.16,17.85-14.34,5.44-28.53,11.58-41.46,19.9-16.49,10.61-28.68,27.35-38.52,44.02-1.62,2.74-3.21,5.65-3.42,8.83-.26,3.98,1.71,7.8,4.2,10.91,23.14,28.89,63.27,34.4,97.99,32.27,20.66-1.26,41.16-5.34,60.72-12.1,17.17-5.93,35.01-10.56,50.41-20.63,5.99-3.91,15.35,19.49,17.64,12.45,3.84-11.81-3.37-19.54,9.17-31.56,6.72-6.44,11.17-17.66,12.86-29.53Zm-19.23-.15c-17.56,24.36-47.59,37.52-75.37,46.28-38.64,12.19-85.34,15.88-124.19,2.08-8.6-3.07-18.62-7.89-24.23-15.42.45-.49.84-.99,1.29-1.48,5.44-6.22,12.19-11.36,19.13-15.89,17.06-11.28,36.15-19.41,55.44-26.18,38.09-13.32,85.32-25.72,125.43-13.87,9.16,2.71,31.64,11.52,22.97,23.81-.16.22-.32.44-.47.66Z"
    />
  </svg>
</template>

<style>
.cls-1 {
  fill: #e43a38;
}

.cls-2 {
  fill: #4e4e4e;
}
</style>
