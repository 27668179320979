<template>
  <RikazAction
    v-if="viewIcon"
    route="/my-account/notifications"
    :has-badge="true"
    :transition-key="unReadCount"
    position="top-0 ltr:left-1 rtl:right-1"
    :label="t('aria_page_label', { pae: t('notifications') })"
  >
    <div class="navbar-action">
      <div class="navbar-link">
        <v-icon icon="mdi mdi-bell-outline" />
      </div>
    </div>

    <template #badge>
      <div v-if="unReadCount" class="action-badge platform">
        <div>{{ unReadCount }}</div>
      </div>
    </template>
  </RikazAction>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~~/store/notifications'
const cart = useCartStore()
const { fetchUnRead, setUnRead } = useNotificationStore()
const { t } = useI18n()
const { isAuthLoggedIn } = useMenuModel()
withDefaults(
  defineProps<{
    viewIcon?: boolean
  }>(),
  {
    viewIcon: true
  }
)
const unReadCount = ref<number>(0)
if (isAuthLoggedIn()) {
  unReadCount.value = await fetchUnRead()
} else {
  unReadCount.value = 0
}
onMounted(() => {
  nextTick(async () => {
    if (!isAuthLoggedIn()) {
      cart.setNewUuid()
      setUnRead(0)
    }

    await cart.serverLoad()
  })
})
</script>
