<template>
  <div>
    <div class="p-4">
      <div class="cursor-pointer" @click="goToRoute('/')">
        <IconsLogoSela class="w-24" />
      </div>

      <div class="border-b-2 bg-bg_color"></div>

      <LayoutNavbarSearchField class="w-full my-2" @close="emit('close')" />

      <div
        v-for="(item, index) in menu"
        :key="`drawer-menu-item-${index}`"
        class="item-drawer"
        :class="{
          active: item.route == route.path
        }"
        @click="goToRoute(item.route)"
      >
        <span class="font-medium">
          {{ item.title }}
        </span>
        <div class="item-text-drawer"></div>
      </div>

      <a href="https://info.sila-sp.com">
        <div class="item-drawer">
          <span class="font-medium"> {{ t('about_sila') }} </span>
          <div class="item-text-drawer"></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const { t } = useI18n()
const emit = defineEmits(['close'])

const menu = [
  { title: t('stores'), route: '/stores' },
  { title: t('offers_discounts'), route: '/offers' }
]

function goToRoute(route: string) {
  emit('close')

  const localePath = useLocalePath()
  useRouter().push(localePath(route))
}
</script>

<style scoped>
.item-drawer {
  @apply py-2 px-5 my-3 rounded-md relative transition-all duration-300 hover:bg-nav_color hover:text-text_alt_color hover:bg-opacity-80 cursor-pointer;
}
.item-drawer.active {
  @apply bg-nav_color text-text_alt_color bg-opacity-80;
}
.item-text-drawer {
  @apply bg-nav_color w-5 h-5 rounded-full border-4 border-bg_color absolute ltr:-left-2.5 rtl:-right-2.5 inset-y-1/2 transform -translate-y-1/2;
}
</style>
