<template>
  <v-menu v-if="cities?.length">
    <template #activator="{ props }">
      <div class="hover:bg-nav_color hover:bg-opacity-10 p-2 mx-0.5 rounded">
        <div
          v-bind="props"
          class="cursor-pointer"
        >
          <ClientOnly>
            <div class="flex">
              <v-icon icon="mdi mdi-map-marker-left-outline" :size="24" />
              <div v-if="selectedCity?.name">
                {{ selectedCity?.name }}
              </div>
              <div v-else class="hidden sm:block">
                {{ t('all_cities') }}
              </div>
            </div>
          </ClientOnly>
        </div>
      </div>
    </template>
    <v-list class="dropdown-menu">
      <div
        class="cursor-pointer my-1 hover:bg-bg_color text-center"
        :class="{ 'bg-bg_color': store.cityId == undefined }"
        @click="(selectedCity?.id !== undefined)? selectCity(undefined):null"
      >
        {{ t('all_cities') }}
      </div>
      <div
        v-for="(city, index) in cities"
        :key="`locale-item ${index}`"
        class="my-1 mx-3  text-center cursor-pointer"
        @click="(selectedCity?.id !== city.id)? selectCity(city.id.toString()):null"
      >
        <ClientOnly>
          <div
            :class="{ 'bg-bg_color': city.id.toString() == store.cityId }"
          >
            {{ city.name }}
          </div>
        </ClientOnly>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { useCityIdStore } from '~/store/city_id_filter'
const { clearCache } = useCacheSession()
const { t } = useI18n()
const { cities } = useCities()
const store = useCityIdStore()

const selectedCity = computed(() => {
  if (store.cityId && cities.value?.length) {
    const city = cities.value.find((el) => el.id.toString() === store.cityId)

    return city
  }

  return null
})

function selectCity(cityId?: string) {
  store.setCityId(cityId)
  clearCache()
  nextTick(() => {
    window.location.reload()
  })
}
</script>
<style scoped>
.dropdown-menu {
  @apply overflow-y-auto bg-bg_alt_color mt-2 text-text_color  max-h-48;
}
</style>
