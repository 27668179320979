<template>
  <div>
    <LayoutFooterStore v-if="!isMobileOrTablet" />
    <img
      v-if="!isMobileOrTablet && !routeIsForStorePage"
      width="256"
      height="241"
      src="/images/sela.webp"
      class="mx-auto"
      :alt="t('sila')"
    />
    <div v-if="!isMobileOrTablet" class="bg-nav_color">
      <LayoutFooterActions />
    </div>
    <div class="h-5"></div>
  </div>
</template>

<script setup lang="ts">
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()

const routeIsForStorePage = computed(() => {
  return /\/stores\/./.test(useRoute().path)
})
</script>
