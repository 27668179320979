<template>
  <RikazDropdown position="inset-x-0 -translate-y-px mt-2">
    <template #trigger="{ open }">
      <RikazSearchField
        class="rounded-full pt-1 pb-2 md:pt-2 px-1 border border-text_color border-opacity-40"
        :class="bgColor"
        :placeholder="t('material_search')"
        input-class="text-sm  font-medium outline-none border-none focus:border-none p-0"
        search-class="pt-1 ltr:pr-1 rtl:pl-1 ltr:pl-px rtl:pr-px pb-px rounded-full"
        :search-size-icon="18"
        close-class="bg-nav_color w-6 h-6 p-1 rounded-full cursor-pointer"
        :prefix="true"
        @search-term="search($event, open)"
      />
    </template>
    <template #content="{ close }">
      <div
        class="flex flex-col text-text_color bg-bg_color h-48 rounded shadow overflow-y-auto p-2"
      >
        <div v-if="materials.length">
          <LayoutNavbarSearchFieldMaterialTile
            v-for="material in materials"
            :key="`search-result-material-${material.id}`"
            :material="material"
            @click="goToMaterial(material.id, close)"
          />
          <div
            class="py-2 mt-auto text-center border-t cursor-pointer"
            @click="goToSearchResults(close)"
          >
            {{ t('view_more') }}
          </div>
        </div>
        <div v-else class="my-auto text-center">{{ t('no_results') }}</div>
      </div>
    </template>
  </RikazDropdown>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { Material } from '~~/composables/useMenuModel'
const emit = defineEmits(['close'])
withDefaults(
  defineProps<{
    bgColor?: string
    textColor?: string
  }>(),
  {
    bgColor: '',
    textColor: 'text-text_color'
  }
)
const toast = useToast()
const materials = ref<Material[]>([])
const term = ref<string | null>(null)
const { t } = useI18n()

async function search(name: string, open: () => void) {
  term.value = name

  if (name) {
    try {
      const { data, error } = await useApiFetch<ApiResponse<[]>>('/material', {
        query: { name }
      })

      if (error?.value) {
        throw error.value
      }

      if (data.value?.data) {
        const { materialModel } = useMenuModel()

        materials.value = data.value.data.map(materialModel) as Material[]

        open()
      }
    } catch (error) {
      toast.error(t('error_while', { operation: t('search') }), {
        timeout: 1000
      })
    }
  }
}

function goToMaterial(id: number, close: () => void) {
  close()
  emit('close')

  const localePath = useLocalePath()
  useRouter().push(localePath({ name: 'materials-id', params: { id } }))
}

function goToSearchResults(close: () => void) {
  close()
  emit('close')

  const localePath = useLocalePath()
  useRouter().push(
    localePath({
      name: 'materials',
      query: { name: term.value }
    })
  )
}
</script>
