<template>
  <div class="bg-nav_color p-2 flex items-center">
    <LayoutNavbarPlatformNotification class="text-bg_color textShadow" />
    <div class="flex-auto"></div>
    <LayoutNavbarSearchField class="w-40" bg-color="bg-bg_color px-2" />
    <div class="flex-auto"></div>

    <div class="text-bg_color textShadow">
      <LayoutNavbarPlatformCitiesFilter />
    </div>
    <Transition v-if="routeIsForMaterialPage()" name="bounce">
      <div :key="cart.items.length">
        <SelaLink to="/cart" :with-branch-id-query="useIsDomain()">
          <div class="relative">
            <v-icon :size="40" class="text-bg_color textShadow">
              <IconsCartBottomNavigation />
            </v-icon>
            <div
              class="absolute top-2 left-1/2 -translate-x-1/3 text-button_color mb-1 font-medium"
            >
              {{ cart.badgeCount }}
            </div>
          </div>
        </SelaLink>
      </div>
    </Transition>
    <slot />
  </div>
</template>
<script setup lang="ts">
const cart = useCartStore()
const { routeIsForMaterialPage } = useMenuModel()
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 1s;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
