<template>
  <v-app class="custom-bg">
    <v-locale-provider :rtl="useIsRtl()">
      <div class="sticky top-0 z-30">
        <LayoutNavbarPlatformNavbar class="w-full" @open-drawer="isOpen = true" />
      </div>

      <div v-if="isMainPage">
        <HomeAdMainSwiper
          :ads="{
            error: getErrors,
            items: typesAnnouncements?.homeBasicAdverts
          }"
        />
        <HomeNavbarStoreTypes />
      </div>

      <v-navigation-drawer
        v-model="isOpen"
        location="right"
        temporary
        class="custom"
      >
        <LayoutNavbarPlatformDrawer @close="isOpen = false" />
      </v-navigation-drawer>

      <main>
        <slot />
      </main>

      <footer>
        <LayoutBottomNavigation
          v-if="isMobileOrTablet&&!routeIsForMaterialPage()"
          class="fixed w-full bottom-0 z-40"
        >
          <template #right>
            <SelaLink to="/" :with-branch-id-query="false" class="link">
              <v-icon icon="mdi mdi-home" :size="24" class="block" />
            </SelaLink>
            <SelaLink to="/stores" :with-branch-id-query="false" class="link">
              <v-icon icon="mdi mdi-view-grid-plus" :size="24" class="block" />
            </SelaLink>
          </template>

          <template #left>
            <SelaLink to="/offers" :with-branch-id-query="false" class="link">
              <v-icon :size="24" class="block">
                <IconsTagPrice />
              </v-icon>
            </SelaLink>
            <SelaLink
              to="/my-account"
              :with-branch-id-query="false"
              class="link"
            >
              <v-icon icon="mdi mdi-account-outline" :size="24" class="block" />
            </SelaLink>
          </template>
        </LayoutBottomNavigation>

        <ClientOnly>
          <LayoutFooter class="mb-20" />
        </ClientOnly>
      </footer>
    </v-locale-provider>
  </v-app>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { routeIsForMaterialPage } = useMenuModel()
const { storeTypeModel } = useMenuModel()
const { getErrors, loadAnnouncements } = useAnnouncementStore()
const typesAnnouncements = ref<TypesAnnouncements | null>(null)
const { setStoreTypes } = useStoreTypes()
const { isMobileOrTablet } = useDevice()
const isOpen = ref(false)
useNuxtApiFetch('/laravel/store-types', {
  query: { limit: 100, with_category: true, with_material_definitions: 1 },
  transform: (data: SuccessResponse<StoreType[]>) => {
    const dataTemp = data.data.map(storeTypeModel)
    setStoreTypes(dataTemp ?? [])
    return dataTemp
  }
})
typesAnnouncements.value = await loadAnnouncements()

const isMainPage = computed(() => {
  return useRoute().path === '/'
})
useHead({
  script: [useSeoLogo(url, url + '/images/sela.webp')]
})
</script>

<style scoped>
.v-navigation-drawer.custom {
  @apply w-full max-w-xs bg-bg_color !important;
}
.custom-bg {
  @apply bg-bg_color text-text_color !important;
}
</style>
